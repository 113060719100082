import { useEffect } from 'react';
import { Card, CardBody, Image } from '@nextui-org/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import alexa from '../assets/alexa.jpg';
import meuTreinoLogo from '../assets/meuTreinoLogo.png';
import mountTraining from '../assets/mountTraining.jpg';
import alexa4 from '../assets/alexa4.jpg';
import { trackPageView } from '../utils/gt';

import { BackButton } from '../components/BackButton';

export const Tutorial = () => {
  const { t } = useTranslation();

  useEffect(() => {
    trackPageView('sync', `${t('appUrl')}/sync`, '/sync');
  }, [t]);

  return (
    <>
      <div className="m-4 sm:m-10 lg:mt-32">
        <div className="grid grid-cols-2 gap-4 mt-4 mx-0 sm:mt-10 sm:mx-auto max-w-[800px] items-center">
          <BackButton routeToGoBack={'/dashboard'} />
          <h1 className="text-5xl text-center mt-6 sm:mt-0 col-span-2">
            {t('pages.Tutorial.syncIsSimple')}
          </h1>
          <Card className="col-span-2 mt-4">
            <CardBody className="h-full">
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2 sm:col-span-1">
                  <Image
                    removeWrapper
                    alt="Card background"
                    className="w-full h-[200px] object-cover"
                    src={alexa4}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <h1 className="text-2xl mb-4">
                    {t('pages.Tutorial.heading4')}
                  </h1>
                  <p>{t('pages.Tutorial.subHeading4')}</p>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="col-span-2 mt-2 sm:mt-4">
            <CardBody className="h-full">
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2 sm:col-span-1">
                  <Image
                    removeWrapper
                    alt="Card background"
                    className="w-full h-[200px] object-cover"
                    src={meuTreinoLogo}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <h1 className="text-2xl mb-4">
                    {t('pages.Tutorial.heading')}
                  </h1>
                  <p>
                    {t('pages.Tutorial.subHeading_1')}{' '}
                    <a href={t('alexaUrl')} target="_blank">
                      {t('pages.Tutorial.subHeading_2')}
                    </a>
                    {t('pages.Tutorial.subHeading_3')}
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="col-span-2 mt-4">
            <CardBody className="h-full">
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2 sm:col-span-1">
                  <Image
                    removeWrapper
                    alt="Card background"
                    className="w-full h-[200px] object-cover"
                    src={mountTraining}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <h1 className="text-2xl mb-4">
                    {t('pages.Tutorial.heading2')}
                  </h1>
                  <p>
                    {t('pages.Tutorial.subHeading2_1')}{' '}
                    <Link to="/workouts">
                      {t('pages.Tutorial.subHeading2_2')}
                    </Link>{' '}
                    {t('pages.Tutorial.subHeading2_3')}
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="col-span-2 mt-4">
            <CardBody className="h-full">
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2 sm:col-span-1">
                  <Image
                    removeWrapper
                    alt="Card background"
                    className="w-full h-[200px] object-cover"
                    src={alexa}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <h1 className="text-2xl mb-4">
                    {t('standalone.alexaStart')} {t('appName')}
                  </h1>
                  <p>{t('pages.Tutorial.subHeading3')}</p>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};
